<template>
  <v-container class="pt-0">
    <back-toolbar title="menu.activedeals"></back-toolbar>
    <item-list
      :items="$store.state.tickets.items"
      v-if="$store.state.tickets.items && $store.state.tickets.items.length > 0"
    ></item-list>
    <error-image
      v-else
      :text="$t('error.noDeals')"
      imageName="noDeals.png"
    ></error-image>
  </v-container>
</template>
<script>
import ItemList from "@/components/recommendations/ItemList";
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";

export default {
  components: {
    ItemList,
    BackToolbar,
    ErrorImage,
  },
};
</script>
